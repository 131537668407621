type State = 'initial'
  | 'loading'
  | 'ready';

export default class ComponentStateManager {
  private state: State;

  constructor(state: State = 'initial') {
    this.state = state;
  }

  public getCurrentState(): State {
    return this.state;
  }

  public setToLoading(): this {
    this.state = 'loading';
    return this;
  }

  public isLoading(): boolean {
    return this.state === 'loading';
  }

  public setToReady(): this {
    this.state = 'ready';
    return this;
  }

  public isReady(): boolean {
    return this.state === 'ready';
  }
}
