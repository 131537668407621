
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import { linkObjectToString } from '@/helpers/converters/url';
import { FetchPolicy } from 'apollo-client';
import Message from '@/components/mixins/Message.vue';
import BaseCard from '@/components/commonComponents/BaseCard.vue';
import ComponentStateManager from '@/helpers/ComponentStateManager';
import { isUndefined } from 'lodash';
import LinkObject from '@/types/LinkTypes';
import EventBus from '@/event-bus';

interface CounterBlockInfo {
  title: string,
  count: number,
  icon: string,
  link: LinkObject,
}

@Component({
  name: 'CardCounter',
  methods: { isUndefined },
  components: { BaseCard },
})
export default class CounterBlock extends Vue {
  @Prop({ required: true })
  private readonly blockId!: number;

  @Prop({ required: true })
  private readonly title!: string;

  private counterBlockInfo: CounterBlockInfo|undefined;

  private stateManager = new ComponentStateManager();

  mounted(): void {
    this.fetchData();
    const forceRerender = () => this.fetchData('network-only');

    EventBus.$on('forceRerender', forceRerender);

    this.$once('hook:beforeDestroy', () => {
      EventBus.$off('forceRerender', forceRerender);
    });
  }

  private handleClick(): void {
    if (this.counterBlockInfo?.link === undefined) {
      throw Error('No link found');
    }

    const url = linkObjectToString(this.counterBlockInfo.link);

    this.$router.push(url);
  }

  private async fetchData(fetchPolicy: FetchPolicy = 'network-only'): Promise<void> {
    this.stateManager.setToLoading();

    const variables = {
      blockId: this.blockId,
    };

    return import('@/graphql/queries/dashboard-counter-block')
      .then(({ default: query }) => this.$apollo.query({
        query,
        fetchPolicy,
        variables,
      }))
      .then((response: { data: { counterBlockInfo: CounterBlockInfo|undefined }}) => {
        const { counterBlockInfo } = response.data;
        if (counterBlockInfo === undefined) {
          throw Error('No data given. This card counter block can\'t be rendered');
        }

        this.counterBlockInfo = counterBlockInfo;
      })
      .catch((error) => {
        Message.error(this.$t('generic.error.occurred'));
        throw error;
      })
      .finally(() => {
        this.stateManager.setToReady();
        this.$emit('ready');
      });
  }
}

